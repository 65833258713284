import {
  createMuiTheme,
  StylesProvider,
  Theme,
  ThemeProvider as MUIThemeProvider,
} from "@material-ui/core/styles";
import React, { useMemo } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";

export const THEME = createMuiTheme({
  palette: {
    primary: {
      main: "#083050",
      light: "#395973",
      dark: "#072B48",
    },
    secondary: {
      main: "#FFCB05",
      light: "#FFD537",
      dark: "#E6B705",
    },
    error: {
      main: "#FE3D20",
      light: "#FE654F",
      dark: "#DF1F01",
    },
    success: {
      main: "#54A075",
      light: "#6CB28A",
      dark: "#417B5A",
    },
    info: {
      main: "#0995EC",
      light: "#26A7F7",
      dark: "#077CC5",
    },
    warning: {
      main: "#FFC847",
      light: "#FFDA85",
      dark: "#FFB60A",
    },
  },
});

export interface ThemeProviderProps {
  theme?: Theme;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ theme: themeProp, children }) => {
  const theme = useMemo(() => (themeProp ? createMuiTheme(THEME, themeProp) : THEME), [themeProp]);
  return (
    <StylesProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
      </MUIThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProvider;
