import compareAsc from "date-fns/compareAsc";
import format from "date-fns/format";
import round from "lodash.round";
import { Measurement, MeasurementValue } from "./types";

export const DATE_FORMAT = "y-MM-dd";
export const CHART_DATE_FORMAT = "yyyy LLL d";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const CHART_DATE_TIME_FORMAT = `${CHART_DATE_FORMAT} ${TIME_FORMAT}`;

export function compareDates(a: Date | string, b: Date | string): number {
  return compareAsc(new Date(a), new Date(b));
}

export function sortMeasurementsByDate<V extends MeasurementValue>(
  measurements: Array<Measurement<V>>,
  direction = 1,
): Array<Measurement<V>> {
  return measurements.sort(([a], [b]) => direction * compareDates(a, b));
}

export function formatDate(date: Date | string): string {
  return format(new Date(date), DATE_FORMAT);
}

export function formatDateTime(date: Date | string): string {
  return format(new Date(date), DATE_TIME_FORMAT);
}

export function formatChartDate(date: Date | string): string {
  return format(new Date(date), CHART_DATE_FORMAT);
}

export function formatChartDateTime(date: Date | string): string {
  return format(new Date(date), CHART_DATE_TIME_FORMAT);
}

export function formatNumber(number: number): number {
  return round(number, 2);
}

export function formatChartValue(value: number, unit: string): string {
  return `${formatNumber(value)} ${unit}`;
}
