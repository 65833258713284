import axios, { AxiosResponse } from "axios";
import { subDays, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { DataInterval, DataSource, MeasurementsDict, SensorResult } from "../types";
import { formatDate } from "../util";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

axios.defaults.withCredentials = true;

// This module will be replaced by the client library's API call functions
// Temporary solution to display the Chart data when testing

export default function useSensorValuesQuery(
  dataSource: DataSource[],
  interval: DataInterval,
): [boolean, MeasurementsDict] {
  // For prevent state change when unmounted
  const [measurements, setMeasurements] = useState<MeasurementsDict>({});
  const [measurementsLoading, setMeasurementsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;
    async function query(): Promise<void> {
      if (dataSource.length > 0) {
        setMeasurementsLoading(true);
        try {
          const sensorData = await Promise.all(
            dataSource.reduce<Array<Promise<AxiosResponse<SensorResult<number>>>>>(
              (acc, { deviceId, sensorKey }) => {
                acc.push(
                  axios.get<SensorResult<number>>(
                    `/device/${deviceId}/sensor/${sensorKey}/measurements/series/`,
                    {
                      params: {
                        date_from: formatDate(
                          subMonths(subDays(new Date(), interval.days), interval.month),
                        ),
                        date_to: formatDate(new Date()),
                      },
                    },
                  ),
                );
                return acc;
              },
              [],
            ),
          );
          const sm = sensorData.reduce<MeasurementsDict>((acc, curr, i) => {
            acc[dataSource[i].dataLabel ?? `${dataSource[i].sensorKey}-${dataSource[i].deviceId}`] =
              curr.data.data;
            return acc;
          }, {});
          if (!isCancelled) {
            setMeasurements(sm);
          }
        } catch {
          // TODO: error handling
        } finally {
          if (!isCancelled) {
            setMeasurementsLoading(false);
          }
        }
      }
    }
    void query();

    return () => {
      // Set the ref value false, so the in the promise
      // we can see if its unmounted
      isCancelled = true;
    };
  }, [dataSource, interval]);

  return [measurementsLoading, measurements];
}
