import { Serie } from "@nivo/line";
import React from "react";
import { ChartProps as ChartComponentProps } from "../Chart/Chart";
import NumberMeasurementChart from "../NumberMeasurementChart";

export interface MassChartProps {
  data: Serie[];
  quantities?: number[];
  deltaTimeInHours: number;
  unit?: string;
  legend?: string;
  displayLegends?: boolean;
  ChartProps?: Partial<ChartComponentProps>;
}

const MassChart: React.FC<MassChartProps> = ({
  data,
  deltaTimeInHours,
  displayLegends = false,
  unit = "kg",
  quantities,
  legend = "Mass",
  ChartProps,
}) => {
  return (
    <NumberMeasurementChart
      data={data}
      unit={unit}
      quantities={quantities}
      deltaTimeInHours={deltaTimeInHours}
      legend={legend}
      legends={
        displayLegends
          ? [
              {
                anchor: "bottom-right",
                direction: "column",
                itemWidth: 100,
                itemHeight: data.length * 15,
                translateX: 110,
              },
            ]
          : []
      }
      {...(displayLegends ? { margin: { top: 50, right: 110, bottom: 50, left: 45 } } : {})}
      min={40}
      max={75}
      {...ChartProps}
    />
  );
};

export default MassChart;
