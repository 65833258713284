import {
  addHours,
  differenceInHours,
  isWithinInterval,
  startOfDay,
  subDays,
  subMonths,
} from "date-fns";
import groupBy from "lodash.groupby";
import isEmpty from "lodash.isempty";
import { useEffect, useState } from "react";
import { DataInterval, DataSource, Measurement, MeasurementsDict } from "../types";
import { compareDates, formatDateTime } from "../util";
import useSensorValuesQuery from "./useSensorValues";

export interface AggregatedData {
  items: Array<Measurement<number>>;
  avg: number;
  interval: {
    from: Date;
    to: Date;
  };
}

export interface NormalizedData {
  dict: Record<string, AggregatedData[]>;
  keys: string[];
}

function groupByIntervalFactory(
  initialDate: Date,
  interval: DataInterval,
): (iteratee: Measurement<number>) => string {
  return function groupByInterval(iteratee: Measurement<number>): string {
    const deltaHours = differenceInHours(new Date(iteratee[0]), initialDate);
    const date = addHours(
      initialDate,
      Math.floor(deltaHours / interval.deltaTimeInHours) * interval.deltaTimeInHours,
    );
    return formatDateTime(date);
  };
}

export function aggregateValues(
  msSeries: Array<Measurement<number>>,
  interval: DataInterval,
  startDate: Date,
): AggregatedData[] {
  const grouped: MeasurementsDict = groupBy(msSeries, groupByIntervalFactory(startDate, interval));

  const sorted = Object.entries(grouped).sort((a, b) => compareDates(a[0], b[0]));

  return sorted.reduce<AggregatedData[]>((acc, [time, ms]) => {
    const sum = ms.reduce((s, [, value]) => s + value, 0);
    const avg = sum / ms.length;
    acc.push({
      items: ms,
      avg,
      interval: { from: new Date(time), to: addHours(new Date(time), interval.deltaTimeInHours) },
    });
    return acc;
  }, []);
}

function convertMeasurementsToChartData(
  values: MeasurementsDict,
  interval: DataInterval,
  end?: Date,
): NormalizedData {
  const startDate = startOfDay(
    subMonths(subDays(end ?? new Date(), interval.days), interval.month),
  );
  return Object.entries(values).reduce<NormalizedData>(
    (acc, [sensorKey, ms]) => {
      const vals = aggregateValues(ms, interval, startDate);
      acc.dict[sensorKey] = vals;
      acc.keys.push(sensorKey);
      return acc;
    },
    { dict: {}, keys: [] },
  );
}

export default function useChartData(
  dataSources: DataSource[],
  initialInterval: DataInterval,
  initialData?: { data: MeasurementsDict; end: Date },
): [boolean, NormalizedData, DataInterval, React.Dispatch<React.SetStateAction<DataInterval>>] {
  const [data, setData] = useState<NormalizedData>({ dict: {}, keys: [] });
  const [interval, setDataInterval] = useState<DataInterval>(initialInterval);
  const [pending, setPending] = useState<boolean>(false);
  const [loading, measurements] = useSensorValuesQuery(dataSources, interval);

  useEffect(() => {
    if (initialData) {
      const dict: MeasurementsDict = {};
      Object.entries(initialData.data).forEach(([sensorKey, ms]) => {
        dict[sensorKey] = ms.filter(([time]) =>
          isWithinInterval(new Date(time), {
            start: subMonths(subDays(initialData.end, interval.days), interval.month),
            end: initialData.end,
          }),
        );
      });
      setData(convertMeasurementsToChartData(dict, interval, initialData.end));
    }
  }, [interval, initialData]);

  useEffect(() => {
    if (!loading && !isEmpty(measurements)) {
      setPending(true);
      setData(convertMeasurementsToChartData(measurements, interval));
      setPending(false);
    }
  }, [measurements, loading, interval]);

  return [loading || pending, data, interval, setDataInterval];
}
