import { Serie } from "@nivo/line";
import { addHours } from "date-fns";
import isNil from "lodash.isnil";
import maxBy from "lodash.maxby";
import minBy from "lodash.minby";
import React from "react";
import { formatChartDateTime, formatChartValue } from "../../util";
import Chart, { ChartProps } from "../Chart";

export interface NumberMeasurementChartProps extends ChartProps {
  unit: string;
  deltaTimeInHours: number;
  quantities?: number[];
  min?: number;
  max?: number;
}

export function calcMaxDataValue(data: Serie[]): number {
  const value = maxBy(
    data.map((item) => maxBy(item.data, (it) => Number(it.y))),
    (item) => Number(item?.y),
  );

  return Number(value?.y);
}

export function calcMinDataValue(data: Serie[]): number {
  const value = minBy(
    data.map((item) => minBy(item.data, (it) => Number(it.y))),
    (item) => Number(item?.y),
  );

  return Number(value?.y);
}

const NumberMeasurementChart: React.FC<NumberMeasurementChartProps> = ({
  data,
  unit,
  deltaTimeInHours,
  quantities,
  min,
  max,
  ...props
}) => {
  const dataMin = calcMinDataValue(data);
  const dataMax = calcMaxDataValue(data);

  let index = 0;
  const xFormat = (value: any): string =>
    `${formatChartDateTime(value)} - ${formatChartDateTime(
      addHours(new Date(value), deltaTimeInHours),
    )}`;

  const yFormat = (value: any): string => {
    if (!isNil(quantities)) {
      const quantity = quantities[index];
      if (quantities.length < index + 1) {
        index = 0;
      } else {
        index += 1;
      }
      return `${formatChartValue(Number(value), unit)} (${quantity})`;
    }
    return formatChartValue(Number(value), unit);
  };
  return (
    <Chart
      data={data}
      xFormat={xFormat}
      yFormat={yFormat}
      axisLeft={{ format: (value) => formatChartValue(Number(value), unit) }}
      yScale={{
        type: "linear",
        min: !isNil(min) && dataMin < min ? dataMin : min ?? "auto",
        max: !isNil(max) && dataMax > max ? dataMax : max ?? "auto",
        stacked: false,
        reverse: false,
      }}
      {...props}
    />
  );
};

export default NumberMeasurementChart;
