import { Serie } from "@nivo/line";
import React from "react";
import { ChartProps as ChartComponentProps } from "../Chart/Chart";
import NumberMeasurementChart from "../NumberMeasurementChart";

export interface TemperatureChartProps {
  legend?: string;
  data: Serie[];
  unit?: string;
  deltaTimeInHours: number;
  quantities?: number[];
  displayLegends?: boolean;
  ChartProps?: Partial<ChartComponentProps>;
}

const TemperatureChart: React.FC<TemperatureChartProps> = ({
  data,
  unit = "℃",
  legend = "Temperature",
  deltaTimeInHours,
  quantities,
  displayLegends = false,
  ChartProps,
}) => {
  return (
    <NumberMeasurementChart
      data={data}
      unit={unit}
      quantities={quantities}
      deltaTimeInHours={deltaTimeInHours}
      legend={legend}
      legends={
        displayLegends
          ? [
              {
                anchor: "bottom-right",
                direction: "column",
                itemWidth: 100,
                itemHeight: data.length * 15,
                translateX: 110,
              },
            ]
          : []
      }
      {...(displayLegends ? { margin: { top: 50, right: 110, bottom: 50, left: 45 } } : {})}
      yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
      {...ChartProps}
    />
  );
};

export default TemperatureChart;
