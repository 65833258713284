import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import formatPhone from "phone";
import React from "react";
import BeeBoxLogoIcon from "../BeeBoxLogoIcon";
import Footer from "../Footer";

export interface BeeBoxFooterProps {
  email: string;
  phone: string;
}

const BeeBoxFooter: React.FC<BeeBoxFooterProps> = ({ email, phone }) => {
  return (
    <Footer>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <BeeBoxLogoIcon />
        </Grid>
        <Grid item>
          <Typography>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Typography>
          <Typography>
            <Link href={`tel:${formatPhone(phone)[0]}`}>{phone}</Link>
          </Typography>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default BeeBoxFooter;
