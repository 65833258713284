import { Grid, Typography } from "@material-ui/core";
import get from "lodash.get";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import SensorIcon, {
  SensorStatus,
  SensorType,
  statusColor,
  StatusProps,
} from "../SensorIcon/SensorIcon";

export interface HivePictogramProps {
  style?: React.CSSProperties;
  hiveName: string;
  gps?: Sensor;
  scale?: Sensor;
  camera?: Sensor;
  microphone?: Sensor;
  humidity?: Sensor;
  temperature?: Sensor;
  secondCamera?: Sensor;
  secondMicrophone?: Sensor;
}

export interface Sensor {
  key: string;
  sensorType: SensorType;
  status: SensorStatus;
}

const StatusColoredBorder = styled.div<StatusProps>(
  (props) => css`
    box-sizing: content-box;
    border: 0.5rem solid ${get(props.theme.palette, statusColor[props.status])};
    border-radius: 4px;
  `,
);

const StyledGrid = styled(Grid)(
  (props) => css`
    flex-grow: 1;
    border: 8px solid ${props.theme.palette.grey[400]};
    border-radius: 4px;
    background-color: ${props.theme.palette.grey[50]};
    padding: ${props.theme.spacing(1)}px;
  `,
);

const GpsBox = styled(StatusColoredBorder)(
  () => css`
    width: 2rem;
    height: 3rem;
  `,
);

const DeviceBox = styled(StatusColoredBorder)(
  (props) => css`
    margin: 0 -${props.theme.spacing(2)}px;
    height: 0.5rem;
    border-radius: 0px;
  `,
);
const BottomBox = styled(StatusColoredBorder)(
  () => css`
    width: 4rem;
    height: 3rem;
  `,
);
const ScaleBox = styled(StatusColoredBorder)(
  (props) => css`
    height: 0.5rem;
    margin-top: ${props.theme.spacing(1)}px;
  `,
);

const StyledName = styled(Typography)(
  (props) => css`
    font-weight: 600;
    color: ${props.theme.palette.grey[800]};
  `,
);

function getFaultyStatus(sensors: Array<Sensor | undefined>): SensorStatus {
  return (
    sensors.find((s) => s?.status === "error")?.status ??
    sensors.find((s) => s?.status === "warning")?.status ??
    SensorStatus.Ok
  );
}

const HivePictogram: React.FC<HivePictogramProps> = ({
  style,
  hiveName,
  gps,
  scale,
  camera,
  microphone,
  humidity,
  temperature,
  secondCamera,
  secondMicrophone,
}) => {
  const sensors = [
    camera ?? { key: "camera-1", status: SensorStatus.Missing, sensorType: SensorType.Camera },
    microphone ?? { key: "mic-1", status: SensorStatus.Missing, sensorType: SensorType.Microphone },
    humidity ?? { key: "hum-1", status: SensorStatus.Missing, sensorType: SensorType.Humidity },
    temperature ?? {
      key: "temp-1",
      status: SensorStatus.Missing,
      sensorType: SensorType.Temperature,
    },
  ];
  const bottomSensors = [secondCamera, secondMicrophone];
  const middleDevice = useMemo(() => {
    return {
      sensors,
      // Device status is the worst status of sensors
      status: !sensors.every((item) => item.status === SensorStatus.Missing)
        ? getFaultyStatus(sensors)
        : SensorStatus.Missing,
    };
  }, [sensors]);

  const bottomDevice = useMemo(() => {
    return {
      // Device status is the worst status of sensors
      status: bottomSensors.find((sensor) => sensor !== undefined)
        ? getFaultyStatus(bottomSensors)
        : SensorStatus.Missing,
    };
  }, [bottomSensors]);

  return (
    <Grid container justify="space-between" direction="column" style={style}>
      <StyledGrid container item justify="space-between" direction="column">
        <Grid container item justify="space-between">
          <Grid item xs={9}>
            <StyledName variant="h6">{hiveName}</StyledName>
          </Grid>
          <Grid item container xs={3} justify="flex-end">
            <GpsBox status={gps?.status ?? SensorStatus.Missing} />
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item container>
            {middleDevice.sensors.map((item) => (
              <SensorIcon key={item.key} status={item.status} sensorType={item.sensorType} />
            ))}
          </Grid>
          <DeviceBox status={middleDevice.status} />
        </Grid>
        <Grid container item justify="center">
          <BottomBox status={bottomDevice.status} />
        </Grid>
      </StyledGrid>
      <Grid item>
        <ScaleBox status={scale?.status ?? SensorStatus.Missing} />
      </Grid>
    </Grid>
  );
};

export default HivePictogram;
