import MUIAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useMemo } from "react";
import styled, { css, DefaultTheme } from "styled-components";
import BackIconButton from "../BackIconButton";
import HideOnScroll from "../HideOnScroll";
import LogoutIconButton from "../LogoutIconButton";

export interface AppBarProps {
  onBack: () => void;
  onLogout: () => void;
  title?: string;
  titleProps?: TypographyProps;
}

const StyledAppBar = styled(MUIAppBar)(
  (props) => css`
    background-color: ${props.theme.palette.primary.main};
    flex-grow: 1;
  `,
);

const StyledNavigation = styled(BackIconButton)(
  (props) => css`
    margin-right: ${props.theme.spacing(2)};
  `,
);

const StyledTitle = styled(Typography)`
  flex-grow: 1;
`;

const AppBar: React.FC<AppBarProps> = ({ onBack, onLogout, title, titleProps }) => {
  const component = useMemo(
    () => (
      <StyledAppBar position="sticky">
        <Toolbar>
          <StyledNavigation onClick={onBack} edge="start" color="inherit" />

          <StyledTitle variant="h6" {...titleProps}>
            {title}
          </StyledTitle>

          <LogoutIconButton onClick={onLogout} edge="end" color="inherit" />
        </Toolbar>
      </StyledAppBar>
    ),
    [onBack, onLogout, title, titleProps],
  );
  const sm = useMediaQuery<DefaultTheme>((theme) => theme.breakpoints.up("sm"), { noSsr: true });
  return sm ? component : <HideOnScroll>{component}</HideOnScroll>;
};

export default AppBar;
