import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import styled, { css } from "styled-components";

export interface BeeBoxMeasurementsCardProps {
  title: string;
  rows: Array<{ label: string; value: string }>;
}

const StyledCard = styled(Card)(
  (props) => css`
    background-color: ${props.theme.palette.secondary.main};
    padding: ${props.theme.spacing(2)}px;
  `,
);

const StyledCardContent = styled(CardContent)`
  padding: 0px;

  /* Overrides Material-UI defaults */
  &:last-child {
    padding-bottom: 0px;
  }
`;

const StyledTitle = styled(Typography)(
  (props) => css`
    margin-bottom: ${props.theme.spacing(2)}px;
    font-weight: bold;
  `,
);

const StyledLabel = styled(Typography)(
  (props) => css`
    color: ${props.theme.palette.text.secondary};
  `,
);

const StyledValue = styled(Typography)(
  () => css`
    font-weight: 600;
  `,
);

function BeeBoxMeasurementsCard({ title, rows }: BeeBoxMeasurementsCardProps): React.ReactElement {
  return (
    <StyledCard>
      <StyledCardContent>
        <StyledTitle>{title}</StyledTitle>
        {rows.map((row) => (
          <Grid key={row.label} container justify="space-between">
            <Grid item>
              <StyledLabel>{row.label}</StyledLabel>
            </Grid>
            <Grid item>
              <StyledValue>{row.value}</StyledValue>
            </Grid>
          </Grid>
        ))}
      </StyledCardContent>
    </StyledCard>
  );
}

export default BeeBoxMeasurementsCard;
