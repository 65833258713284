import { Theme, useMediaQuery } from "@material-ui/core";
import { LineSvgProps, ResponsiveLine } from "@nivo/line";
import React from "react";
import { formatChartDate, formatChartDateTime, formatNumber } from "../../util";

export interface ChartProps extends LineSvgProps {
  legend?: string;
}

const Chart: React.FC<ChartProps> = ({ data, legend, ...props }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 50, bottom: 50, left: 45 }}
      xFormat={(value) => formatChartDateTime(new Date(value))}
      yFormat={(value) => formatNumber(Number(value))}
      xScale={{ type: "time", format: "%Y-%m-%d %H:%M", useUTC: false }}
      yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
      axisBottom={{
        format: (value) => formatChartDate(new Date(value)),
        orient: "bottom",
        tickRotation: 15,
        tickValues: isMobile ? 3 : 15,
        legend,
        legendOffset: 40,
        legendPosition: "middle",
      }}
      colors={{ scheme: "nivo" }}
      enablePoints={false}
      pointSize={10}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh
      {...props}
    />
  );
};

export default Chart;
