import {
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Serie } from "@nivo/line";
import React from "react";
import { NormalizedData, useChartData } from "../../hooks";
import { DataInterval, DataSource, MeasurementsDict } from "../../types";
import { formatDateTime } from "../../util";

export const INTERVALS: DataInterval[] = [
  {
    key: "1",
    label: "Elmúlt 1 hét",
    days: 7,
    month: 0,
    deltaTimeInHours: 1,
  },
  {
    key: "2",
    label: "Elmúlt 1 hónap",
    days: 0,
    month: 1,
    deltaTimeInHours: 6,
  },
  {
    key: "3",
    label: "Elmúlt 3 hónap",
    days: 0,
    month: 3,
    deltaTimeInHours: 24,
  },
];

function chartDataToSeries(data: NormalizedData): Serie[] {
  const series = data.keys.map((key) => {
    const values = data.dict[key];
    return {
      id: key,
      data: values.map((item) => ({
        x: formatDateTime(item.interval.from),
        y: item.avg,
      })),
    };
  });
  return series;
}

function chartDataToQuantites(data: NormalizedData): number[] {
  const quantities = data.keys.flatMap((key) => {
    const values = data.dict[key];

    return values.map(({ items }) => items.length);
  });
  return quantities;
}

export interface ChartContainerProps {
  title: string;
  dataSources: DataSource[];
  style?: CSSProperties;
  // For testing purpuses
  initialData?: { data: MeasurementsDict; end: Date };
  children: (data: Serie[], interval: DataInterval, quantities: number[]) => JSX.Element;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  dataSources,
  style,
  initialData,
  children,
}) => {
  const [loading, data, interval, setDataInterval] = useChartData(
    dataSources,
    INTERVALS[0],
    initialData,
  );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  return (
    <div style={style}>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2" style={{ fontStyle: "italic" }}>
            Aggr. blocks: {interval.deltaTimeInHours} h
          </Typography>
        </Grid>
        <Grid item>
          <Grid item container alignItems="center">
            {!isMobile && (
              <Typography style={{ margin: "0.5em" }} variant="h6">
                Nézet:
              </Typography>
            )}
            <TextField
              id="select-interval"
              select
              value={interval.key}
              onChange={(e) => {
                setDataInterval(
                  INTERVALS.find((item) => item.key === e.target.value) ?? INTERVALS[0],
                );
              }}
              variant="outlined"
            >
              {INTERVALS.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <CircularProgress />
      ) : (
        children(chartDataToSeries(data), interval, chartDataToQuantites(data))
      )}
    </div>
  );
};

export default ChartContainer;
